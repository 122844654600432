import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {site_routes} from './router';

const AppRouter = () => {

    return (
        <Routes>
            {site_routes.map(route => <Route element={route.component} path={route.path} exact={route.exact}
                                             key={route.path}/>)}
        </Routes>
    );
};

export default AppRouter;
