import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {IconButton} from "@mui/material";
import {Done, NotInterested} from "@mui/icons-material";
import * as c from "../../constants";
import Chip from "@mui/material/Chip";
import moment from "moment-timezone";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "@mui/material/Tooltip";
import QRCode from "react-qr-code";
import ListItem from "@mui/material/ListItem";


const CouponView = (props) => {
    const handleClose = () => {
        props.onClose();
    };

    if (!!!props.coupon)
        return null;

    return (
        <Dialog open={!!props.coupon} onClose={handleClose} PaperProps={{component: 'div'}} maxWidth={"sm"} fullWidth>
            <DialogTitle>Coupon <b>{!!props.coupon ? props.coupon.coupon_id : ""}</b> info</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">Status</TableCell>
                            <TableCell>
                                <Chip label={c.get_coupon_status_by_id(props.coupon.status)} sx={{minWidth: 100}}
                                      color={props.coupon.status === c.COUPON_STATUS.ACTIVE ? "success" : (props.coupon.status === c.COUPON_STATUS.USED ? "info" : "error")}
                                      variant="outlined"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Name</TableCell>
                            <TableCell>{props.coupon.coupon_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Created at</TableCell>
                            <TableCell>{moment.utc(props.coupon.create_datetime).tz(moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Valid dates</TableCell>
                            <TableCell>{moment(props.coupon.valid_from).format("DD.MM.YYYY")} - {moment(props.coupon.valid_to).format("DD.MM.YYYY")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Coupon working time</TableCell>
                            <TableCell>{moment(props.coupon.valid_time_from, "HH:mm:ss").format("HH:mm")} - {moment(props.coupon.valid_time_to, "HH:mm:ss").format("HH:mm")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Device</TableCell>
                            <TableCell>{props.coupon.device_no}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Total limit</TableCell>
                            <TableCell>{props.coupon.total_limit === 0 ? "∞" : props.coupon.total_limit}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Day limit</TableCell>
                            <TableCell>{props.coupon.day_limit === 0 ? "∞" : props.coupon.day_limit}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Discount</TableCell>
                            <TableCell>{props.coupon.amount_value}{props.coupon.amount_type === c.DISCOUNT_TYPE.PERCENT ? "%" : " money unit"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Product types</TableCell>
                            <TableCell>{props.coupon.product_types.includes(0) ? "All" : props.productTypeList.filter(val => props.coupon.product_types.includes(val.product_type_id)).map(val => val.name).join(", ")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Created by</TableCell>
                            <TableCell>{`${props.coupon.user_name} (${props.coupon.user_id})`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Description</TableCell>
                            <TableCell>{props.coupon.description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">URL</TableCell>
                            <TableCell>
                                {props.coupon.url && <Box component={"div"} sx={{display: "flex"}}>
                                    <Link href={props.coupon.url}>{props.coupon.url}</Link>
                                    <Tooltip title="Copy URL">
                                        <IconButton aria-label="copy_url"
                                                    onClick={() => navigator.clipboard.writeText(props.coupon.url)}>
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">QR</TableCell>
                            <TableCell>
                                <Box component={"div"} sx={{display: "flex"}}>
                                    {props.coupon.url && <QRCode
                                        size={256}
                                        style={{height: "auto", maxWidth: "30%", width: "30%"}}
                                        value={props.coupon.url}
                                        viewBox={`0 0 256 256`}
                                    />}
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Emails</TableCell>
                            <TableCell>
                                {props.coupon.emails.map((email, index) => (
                                    <ListItem button key={`${email}_${index}`} component="a" href={`mailto:${email}`}>
                                        {email}
                                    </ListItem>
                                    // <Box component={"div"} sx={{display: "flex"}} key={`${email}_${index}`}>
                                    //     <a href={`mailto:${email}`} target="_top">{email}</a>
                                    // </Box>
                                ))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )

};

export default CouponView;
