import React from "react";
import LoginPage from "../UI/LoginPage/LoginPage";
import MainPage from "../UI/MainPage/MainPage";
import NotFoundPage from "../UI/ErrorPage/NotFoundPage";
import Forbidden from "../UI/ErrorPage/Forbidden";
import Users from "../UI/Users/Users";
import Devices from "../UI/Devices/Devices";
import Coupons from "../UI/Coupons/Coupons";
import Discounts from "../UI/Discounts/Discounts";
//import SalesPage from "../UI/SalesPage/SalesPage";


export const site_routes = [
    {path: '/', component: <Coupons/>, exact: false},
    {path: '/cp', component: <Coupons/>, exact: false},
    {path: '/login', component: <LoginPage/>, exact: false},
    {path: '/users', component: <Users/>, exact: false},
    {path: '/devices', component: <Devices/>, exact: false},
    {path: '/coupons', component: <Coupons/>, exact: false},
    {path: '/discounts', component: <Discounts/>, exact: false},
    {path: '/forbidden', component: <Forbidden/>, exact: false},
    {path: '/cp/login', component: <LoginPage/>, exact: false},
    {path: '/cp/users', component: <Users/>, exact: false},
    {path: '/cp/devices', component: <Devices/>, exact: false},
    {path: '/cp/coupons', component: <Coupons/>, exact: false},
    {path: '/cp/discounts', component: <Discounts/>, exact: false},
    {path: '/cp/forbidden', component: <Forbidden/>, exact: false},
    {path: '*', component: <NotFoundPage/>, exact: false}
];
