import React from "react";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";
import Typography from "@mui/material/Typography";

const Forbidden = () => {

    return (
        <React.Fragment>
            <Typography variant="h3">
                Forbidden
            </Typography>
        </React.Fragment>
    );
};

export default Forbidden;
