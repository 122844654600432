import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment-timezone";


const DeviceEdit = (props) => {
    const dispatch = useContext(DispatchContext);
    const [savingError, setSavingError] = useState("");
    const [deviceNo, setDeviceNo] = useState("");
    const [ipInt, setIpInt] = useState("");
    const [ipExt, setIpExt] = useState("");
    //const [adminApiKey, setAdminApiKey] = useState("");
    const [apiUser, setApiUser] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [description, setDescription] = useState("");
    const [comment, setComment] = useState("");
    const [isActive, setIsActive] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        setSavingError("");

        const edit_device_obj = {
            ...formJson,
            device_id: props.device.device_id,
            is_active: !!formJson.is_active
        };
        //console.log(new_device_obj);
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.deviceEdit(edit_device_obj).then((response) => {
            if (response.err) {
                setSavingError(response.err);
                return;
            }
            setSavingError("");
            props.onSave();
            props.onClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };

    useEffect(() => {
        setDeviceNo(props.device && props.device.device_no ? props.device.device_no : "");
        setIpInt(props.device && props.device.ip_int ? props.device.ip_int : "");
        setIpExt(props.device && props.device.ip_ext ? props.device.ip_ext : "");
        //setAdminApiKey(props.device && props.device.admin_api_key ? props.device.admin_api_key : "");
        setApiUser(props.device && props.device.dev_api_user ? props.device.dev_api_user : "");
        setApiKey(props.device && props.device.dev_api_key ? props.device.dev_api_key : "");
        setDescription(props.device && props.device.description ? props.device.description : "");
        setComment(props.device && props.device.comment ? props.device.comment : "");
        setIsActive(props.device && props.device.is_active ? props.device.is_active : false);
    }, [props.device]);

    const handleClose = () => {
        setSavingError("");

        props.onClose();
    };

    if (!!!props.device)
        return null;

    return (
        <Dialog
            maxWidth={"sm"} fullWidth
            open={!!props.device}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Edit device <b>{!!props.device ? props.device.device_no : ""}</b></DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="device_no"
                    name="device_no"
                    label="Number"
                    type="number"
                    inputProps={{min: 1, max: 9999}}
                    fullWidth
                    variant="outlined"
                    value={deviceNo}
                    onChange={(e) => {
                        setDeviceNo(e.target.value);
                    }}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="ip_int"
                    name="ip_int"
                    label="IP Int"
                    type="text"
                    inputProps={{minLength: 9, maxLength: 21}}
                    fullWidth
                    variant="outlined"
                    value={ipInt}
                    onChange={(e) => {
                        setIpInt(e.target.value);
                    }}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="ip_ext"
                    name="ip_ext"
                    label="IP Ext"
                    type="text"
                    inputProps={{minLength: 9, maxLength: 21}}
                    fullWidth
                    variant="outlined"
                    value={ipExt}
                    onChange={(e) => {
                        setIpExt(e.target.value);
                    }}
                />
                {/*<TextField*/}
                {/*    autoFocus*/}
                {/*    required*/}
                {/*    margin="dense"*/}
                {/*    id="admin_api_key"*/}
                {/*    name="admin_api_key"*/}
                {/*    label="Admin API Key"*/}
                {/*    type="text"*/}
                {/*    inputProps={{minLength: 6, maxLength: 30}}*/}
                {/*    fullWidth*/}
                {/*    variant="outlined"*/}
                {/*    value={adminApiKey}*/}
                {/*    onChange={(e) => {*/}
                {/*        setAdminApiKey(e.target.value);*/}
                {/*    }}*/}
                {/*/>*/}
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="dev_api_user"
                    name="dev_api_user"
                    label="API User"
                    type="text"
                    inputProps={{minLength: 4, maxLength: 30}}
                    fullWidth
                    variant="outlined"
                    value={apiUser}
                    onChange={(e) => {
                        setApiUser(e.target.value);
                    }}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="dev_api_key"
                    name="dev_api_key"
                    label="API Key"
                    type="text"
                    inputProps={{minLength: 4, maxLength: 30}}
                    fullWidth
                    variant="outlined"
                    value={apiKey}
                    onChange={(e) => {
                        setApiKey(e.target.value);
                    }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    inputProps={{maxLength: 100}}
                    fullWidth
                    variant="outlined"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                />
                <TextField
                    autoFocus
                    multiline
                    rows={3}
                    margin="dense"
                    id="comment"
                    name="comment"
                    label="Comment"
                    type="text"
                    inputProps={{maxLength: 250}}
                    fullWidth
                    variant="outlined"
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="timezone-select-label">Timezone</InputLabel>
                    <Select
                        labelId="timezone-select-label"
                        id="timezone-select"
                        name="timezone"
                        //value={age}
                        label="Timezone"
                        defaultValue={Object.keys(c.ALL_TIMEZONES).includes(props.device.timezone) ? props.device.timezone : "GMT"}
                        //onChange={handleChange}
                    >
                        {Object.keys(c.ALL_TIMEZONES).map((key) => (
                            <MenuItem value={key}
                                      key={key}>{`${moment().tz(key).format('Z')} ${c.ALL_TIMEZONES[key]}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormGroup variant="outlined" margin="dense">
                    <FormControlLabel id="is_active" name="is_active" label="Active"
                                      control={<Checkbox checked={isActive} onChange={(e) => {
                                          setIsActive(e.target.checked);
                                      }}/>}/>
                </FormGroup>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </Dialog>
    )

};

export default DeviceEdit;
