import React, {useContext, useEffect, useMemo, useState} from 'react';
import DispatchContext from "../../DispatchContext";
import moment from "moment-timezone";
import StateContext from "../../StateContext";
import {useNavigate} from "react-router";
import * as c from "../../constants";
import ServerService from "../../API/ServerService";
import LoadingIconSpan from "../Accessory/LoadingIconSpan";
import {
    Box,
    Button,
    Typography,
    IconButton,
    Paper,
    Tooltip
} from '@mui/material';
import {Delete, Edit, NotInterested, Done, Search} from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import BlockIcon from '@mui/icons-material/Block';
import {MaterialReactTable} from 'material-react-table';
import axios from "axios";
import CouponCreate from "./CouponCreate";

import CouponRemove from "./CouponRemove";
import CouponView from "./CouponView";
import CouponCancel from "./CouponCancel";
import Chip from "@mui/material/Chip";
import {Link} from "react-router-dom";

const Coupons = () => {
    const [couponList, setCouponList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    //const [userRolesList, setUserRolesList] = useState([]);
    const [needTableUpdate, setNeedTableUpdate] = useState(true);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [removeCouponModalOpen, setRemoveCouponModalOpen] = useState(null);
    const [cancelCouponModalOpen, setCancelCouponModalOpen] = useState(null);
    //const [editCouponModalOpen, setCouponDeviceModalOpen] = useState(null);
    const [viewCouponModalOpen, setViewCouponModalOpen] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25, //customize the default page size
    });
    const state = useContext(StateContext);
    const navigate = useNavigate();
    const url_params = new URLSearchParams(window.location.search);
    const coupon_id = Number(url_params.get('coupon_id')) ? Number(url_params.get('coupon_id')) : 0;

    const columns = [
        {
            accessorKey: 'status',
            header: 'Status',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                <Chip label={c.get_coupon_status_by_id(renderedCellValue)} sx={{minWidth: 100}}
                      color={renderedCellValue === c.COUPON_STATUS.ACTIVE ? "success" : (renderedCellValue === c.COUPON_STATUS.USED ? "info" : "error")}
                      variant="outlined"/>
            ),
        },
        {
            accessorKey: 'coupon_name',
            header: 'Name',
            size: 140,
            Cell: ({renderedCellValue, row}) => (
                renderedCellValue ? (renderedCellValue.length > 30 ?
                    <Tooltip arrow placement="left" title={renderedCellValue}>
                        <div>{`${renderedCellValue.slice(0,27)}...`}</div>
                    </Tooltip> : renderedCellValue) : ""
            ),
        },
        {
            accessorKey: 'device_no',
            header: 'Device',
            size: 30
        },
        {
            accessorKey: 'use_count',
            header: 'Use count',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                <Link to={`/cp/discounts?coupon_id=${row.original.coupon_id}`}>{renderedCellValue}</Link>
            ),
        },
        {
            accessorKey: 'valid_to',
            header: 'Valid to',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                moment(renderedCellValue).format("DD.MM.YY")
            ),
        },
        {
            accessorKey: 'amount_value',
            header: 'Discount',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                row.original.amount_type !== c.DISCOUNT_TYPE.PERCENT ? `${renderedCellValue}$` : `${renderedCellValue}%`
            ),
        },
        {
            accessorKey: 'total_limit',
            header: 'Limit',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                renderedCellValue === 0 ? "∞" : renderedCellValue
            ),
        },
        {
            accessorKey: 'day_limit',
            header: 'Day limit',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                renderedCellValue === 0 ? "∞" : renderedCellValue
            ),
        }
    ];

    useEffect(() => {
        if (!needTableUpdate)
            return;
        let axios_controller = new AbortController();
        ServerService.getCoupons(coupon_id, pagination.pageIndex, pagination.pageSize, axios_controller).then((response) => {
            if (response.coupons)
                setCouponList(response.coupons);
            if (response.devices)
                setDeviceList(response.devices);
            if (response.product_types)
                setProductTypeList(response.product_types);
            if (response.coupons_count)
                setRowCount(response.coupons_count);
            //if (response.roles)
            //    setUserRolesList(response.roles);
            setNeedTableUpdate(false);
        }).catch((e) => {
            if (axios.isCancel(e)) {
                return;
            }
            setNeedTableUpdate(false);
        }).finally(() => {

        });
        return (() => {
            axios_controller.abort();
        });
    }, [needTableUpdate]);

    useEffect(() => {
        setNeedTableUpdate(true);
    }, [pagination.pageIndex, pagination.pageSize, coupon_id]);

    if (needTableUpdate)
        return (
            <LoadingIconSpan is_visible={true}/>
        );

    return (
        <React.Fragment>
            <CouponView coupon={viewCouponModalOpen} productTypeList={productTypeList} onClose={() => setViewCouponModalOpen(null)}/>
            <CouponRemove coupon={removeCouponModalOpen} onClose={() => setRemoveCouponModalOpen(null)}
                          onSave={() => {
                              setNeedTableUpdate(true)
                          }}/>
            <CouponCancel coupon={cancelCouponModalOpen} onClose={() => setCancelCouponModalOpen(null)}
                          onSave={() => {
                              setNeedTableUpdate(true)
                          }}/>
            <CouponCreate onClose={() => setCreateModalOpen(false)} open={createModalOpen} deviceList={deviceList}
                          productTypeList={productTypeList}
                          onSave={() => {
                              setNeedTableUpdate(true)
                          }}/>

            <Paper style={{maxWidth: "70rem", margin: "auto"}}>
                <Typography component="h1" variant="h4">Coupons</Typography>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 80,
                        },
                    }}
                    initialState={{density: 'compact'}}
                    enablePagination={true}
                    manualPagination={true}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    state={{pagination}} //pass the pagination state to the table
                    rowCount={rowCount}
                    columns={columns}
                    data={couponList}
                    enableEditing
                    enableBottomToolbar={true}
                    enableGlobalFilter={false} //disable search feature
                    enableFilterMatchHighlighting={false}
                    enableColumnActions={false}
                    enableSorting={false} //disable sorting for all columns
                    enableToolbarInternalActions={false}
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip arrow placement="left" title="View">
                                <IconButton onClick={() => {
                                    setViewCouponModalOpen(row.original);
                                }}>
                                    <Search/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="left" title="Copy link">
                                <IconButton onClick={() => {
                                    navigator.clipboard.writeText(row.original.url);
                                }}>
                                    <LinkIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Cancel">
                                <Box component={"span"}>
                                <IconButton onClick={() => {
                                    setCancelCouponModalOpen(row.original);
                                }} disabled={row.original.status !== c.COUPON_STATUS.ACTIVE}>
                                    <BlockIcon/>
                                </IconButton>
                                </Box>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton onClick={() => {
                                    setRemoveCouponModalOpen(row.original);
                                }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
                            Create
                        </Button>
                    )}
                />
            </Paper>
        </React.Fragment>
    );
};

export default Coupons;
