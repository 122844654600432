import React from 'react';
import * as c from './constants'


const initialState = {
    devices: [],
    user: JSON.parse(localStorage.getItem("user")),
    is_page_loading: false
};


const StateContext = React.createContext(initialState);

export {StateContext, initialState};
export default StateContext;
