import React, {useEffect, useReducer, useState} from "react";
import './App.css';
import {BrowserRouter} from "react-router-dom"
import AppRouter from "./components/AppRouter";
import appReducer from "./components/appReducer";
import {StateContext, initialState} from "./components/StateContext";
import DispatchContext from "./components/DispatchContext";
import LoadingIcon from "./components/UI/Accessory/LoadingIcon";
//import {SocketContext, socket} from './components/SocketContext';
import createTheme from "@mui/material/styles/createTheme";
import useTheme from "@mui/material/styles/useTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {ruRU} from "@mui/material/locale";
import AppHeader from "./components/UI/AppHeader/AppHeader";
import AppFooter from "./components/UI/AppFooter/AppFooter";

function App() {
    const [state, dispatch] = useReducer(appReducer, initialState, (init_obj) => {
        return init_obj
    });
    const theme = useTheme();
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, ruRU),
        [],
    );

    return (
        <BrowserRouter>
            <DispatchContext.Provider value={dispatch}>
                <StateContext.Provider value={state}>
                    <div className="App">
                        <React.StrictMode>
                            <ThemeProvider theme={themeWithLocale}>
                                <AppHeader/>
                                <AppRouter/>
                                <AppFooter/>
                                <LoadingIcon/>
                            </ThemeProvider>
                        </React.StrictMode>
                    </div>
                </StateContext.Provider>
            </DispatchContext.Provider>
        </BrowserRouter>

    );
}

export default App;
