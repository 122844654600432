import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";
import {sha256} from "js-sha256";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import MenuProps from "../Accessory/MenuProps";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";


const UserCreate = (props) => {
    const max_role_id = props.roleList.reduce((max_value, role) => {
        if (role.user_role_id > max_value)
            return role.user_role_id;
        return max_value
    }, 1);
    const dispatch = useContext(DispatchContext);
    const [savingError, setSavingError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState(max_role_id);
    const [userDeviceList, setUserDeviceList] = useState([]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        setSavingError("");
        if (formJson.password1 !== formJson.password2) {
            setSavingError("The passwords are not equal");
            return;
        }
        //console.log({...formJson, device_list: formJson.device_list ? formJson.device_list.split(",") : []});
        //return;
        const new_user_obj = {
            email: formJson.email,
            user_role_id: parseInt(formJson.user_role_id),
            device_list: formJson.device_list ? formJson.device_list.split(",") : [],
            password: sha256(`${formJson.email}+${formJson.password1}`),
            name: formJson.name,
            is_active: !!formJson.is_active
        };
        //console.log(new_user_obj);
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.userCreate(new_user_obj).then((response) => {
            if (response.err) {
                setSavingError(response.err);
                return;
            }
            setSavingError("");
            props.onSave();
            props.onClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };

    const handleClose = () => {
        setSavingError("");
        props.onClose();
    };

    const handleRoleChange = (role_id) => {
        setSelectedRoleId(role_id);
    };

    const handleDeviceChange = (event) => {
        const {
            target: { value },
        } = event;
        setUserDeviceList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Create a new user</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                />
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel id="user_role_id">Role</InputLabel>
                    <Select
                        autoFocus
                        required
                        labelId="user_role_id"
                        id="user_role_id"
                        name="user_role_id"
                        value={selectedRoleId}
                        label="Role"
                        variant="outlined"
                        onChange={(event) => handleRoleChange(event.target.value)}
                    >
                        {
                            props.roleList.map((cur_role) =>
                                <MenuItem key={cur_role.user_role_id + cur_role.role_name}
                                          value={cur_role.user_role_id}>{cur_role.role_name}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel htmlFor="password1">New password *</InputLabel>
                    <OutlinedInput
                        id="password1"
                        autoFocus
                        required
                        type={showPassword ? 'text' : 'password'}
                        name="password1"
                        label="New password"
                        fullWidth
                        inputProps={{minLength: 5}}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel htmlFor="password2">Repeat password *</InputLabel>
                    <OutlinedInput
                        id="password2"
                        autoFocus
                        required
                        type={showPassword ? 'text' : 'password'}
                        name="password2"
                        label="Repeat password"
                        fullWidth
                        inputProps={{minLength: 5}}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {selectedRoleId !== c.USER_ROLES.ADMIN &&
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel id="select_device_label">Devices</InputLabel>
                    <Select
                        labelId="select_device_label"
                        id="user_device_list"
                        name="device_list"
                        required
                        autoFocus
                        multiple
                        value={userDeviceList}
                        onChange={handleDeviceChange}
                        input={<OutlinedInput id="select_device" label="Device" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {
                                    props.deviceList.filter(dev => selected.includes(dev.device_id)).map((dev) => (
                                    <Chip key={dev.device_id} label={dev.device_no} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        variant="outlined"
                    >
                        {props.deviceList.map((device) => (
                            <MenuItem
                                href={null}
                                key={device.device_id}
                                value={device.device_id}
                                //style={getStyles(name, personName, theme)}
                            >
                                {device.device_no}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    inputProps={{minLength: 3, maxLength: 30}}
                    fullWidth
                    variant="outlined"
                />

                <FormGroup variant="outlined" margin="dense">
                    <FormControlLabel id="is_active" name="is_active" control={<Checkbox defaultChecked/>}
                                      label="Active"/>
                </FormGroup>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button href={null} onClick={handleClose}>Cancel</Button>
                <Button href={null} type="submit">Create</Button>
            </DialogActions>
        </Dialog>
    )

};

export default UserCreate;
