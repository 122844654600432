import React, {useContext, useEffect, useMemo, useState} from 'react';
import DispatchContext from "../../DispatchContext";
import StateContext from "../../StateContext";
import {useNavigate} from "react-router";
import * as c from "../../constants";
import ServerService from "../../API/ServerService";
import LoadingIconSpan from "../Accessory/LoadingIconSpan";
import {
    Box,
    Button,
    Typography,
    IconButton,
    Tooltip
} from '@mui/material';
import {Delete, Edit, Password, NotInterested, Done} from '@mui/icons-material';
import {MaterialReactTable} from 'material-react-table';
import UserChangePassword from "./UserChangePassword";
import axios from "axios";
import UserCreate from "./UserCreate";
import Paper from "@mui/material/Paper";
import UserRemove from "./UserRemove";
import UserEdit from "./UserEdit";

const Users = () => {
    const [userList, setUserList] = useState([]);
    const [userRolesList, setUserRolesList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [needTableUpdate, setNeedTableUpdate] = useState(true);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(null);
    const [removeUserModalOpen, setRemoveUserModalOpen] = useState(null);
    const [editUserModalOpen, setEditUserModalOpen] = useState(null);
    const state = useContext(StateContext);
    const navigate = useNavigate();

    const columns = [
        {
            accessorKey: 'user_id',
            header: 'ID',
            enableColumnOrdering: false,
            enableEditing: false, //disable editing on this column
            enableSorting: false,
            size: 40,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: 140
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 140
        },
        {
            accessorKey: 'role_name',
            header: 'Role',
            size: 40
        },
        {
            accessorKey: 'dev_list',
            header: 'Devices',
            size: 100,
            Cell: ({renderedCellValue, row}) => (
                //JSON.stringify(row)
                row.original.user_role_id === c.USER_ROLES.ADMIN ? "All":
                    renderedCellValue.map(dev => dev.device_no).join(" ")
            ),
        },
        {
            accessorKey: 'is_active',
            header: 'Active',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                renderedCellValue ? <IconButton color="info"><Done/></IconButton> :
                    <IconButton color="error"><NotInterested/></IconButton>
            ),
        }
    ];

    useEffect(() => {
        if (!needTableUpdate)
            return;
        let axios_controller = new AbortController();
        ServerService.getUsers(axios_controller).then((response) => {
            if (response.users)
                setUserList(response.users);
            if (response.roles)
                setUserRolesList(response.roles);
            if (response.devices)
                setDeviceList(response.devices);
            setNeedTableUpdate(false);
        }).catch((e) => {
            if (axios.isCancel(e)) {
                return;
            }
            setNeedTableUpdate(false);
        }).finally(() => {

        });
        return (() => {
            axios_controller.abort();
        });
    }, [needTableUpdate]);

    if (needTableUpdate)
        return (
            <LoadingIconSpan is_visible={true}/>
        );
    return (
        <React.Fragment>
            <UserChangePassword user={changePasswordModalOpen} onClose={() => setChangePasswordModalOpen(null)}/>
            <UserEdit user={editUserModalOpen} roleList={userRolesList} onClose={() => setEditUserModalOpen(null)}
                      deviceList={deviceList}
                      onSave={() => {
                          setNeedTableUpdate(true)
                      }}/>
            <UserRemove user={removeUserModalOpen} onClose={() => setRemoveUserModalOpen(null)}
                        onSave={() => {
                            setNeedTableUpdate(true)
                        }}/>
            <UserCreate roleList={userRolesList} onClose={() => setCreateModalOpen(false)} open={createModalOpen}
                        deviceList={deviceList}
                        onSave={() => {
                            setNeedTableUpdate(true)
                        }}/>
            <Paper style={{maxWidth: "70rem", margin: "auto"}}>
                <Typography component="h1" variant="h4">Users</Typography>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    initialState={{density: 'compact'}}
                    enablePagination={false}
                    columns={columns}
                    data={userList}
                    enableEditing
                    enableBottomToolbar={false}
                    enableGlobalFilter={false} //disable search feature
                    enableFilterMatchHighlighting={false}
                    enableColumnActions={false}
                    enableSorting={false} //disable sorting for all columns
                    enableToolbarInternalActions={false}
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => {
                                    setEditUserModalOpen(row.original);
                                }}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton onClick={() => {
                                    setRemoveUserModalOpen(row.original);
                                }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Change password">
                                <IconButton onClick={() => {
                                    setChangePasswordModalOpen(row.original);
                                }}>
                                    <Password/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
                            Create
                        </Button>
                    )}
                />
            </Paper>
        </React.Fragment>
    );
};

export default Users;
