import React from 'react';
import classes from './AppFooter.module.css'

const AppFooter = () => {
    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export default AppFooter;
