import React from "react";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";
import Typography from "@mui/material/Typography";

const NotFoundPage = () => {

    return (
        <React.Fragment>
            <Typography variant="h3">
                Page not found
            </Typography>
        </React.Fragment>
    );
};

export default NotFoundPage;
