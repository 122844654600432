import React, {useContext} from "react";
import classes from './LoadingIcon.module.css'
import StateContext from "../../StateContext";
import DispatchContext from "../../DispatchContext";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingIcon = (props) => {
    const state = useContext(StateContext);

    if (!state.is_page_loading)
        return null;

    return (
        <div className={classes.loading_icon__wrapper}>
            <CircularProgress color="inherit" className={classes.loading_icon__wrapper__fullpage}/>
        </div>);
};

export default LoadingIcon;

