import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";
import {sha256} from "js-sha256";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";


const UserChangePassword = (props) => {
    const dispatch = useContext(DispatchContext);
    const [savingError, setSavingError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const password1 = formJson.password1;
        const password2 = formJson.password2;
        setSavingError("");
        if (password1 !== password2) {
            setSavingError("The passwords are not equal");
            return;
        }
        dispatch({type: c.SET_LOADING_PAGE, payload: true});

        ServerService.userPasswordChange({
            user_id: props.user.user_id,
            new_password: sha256(`${props.user.email}+${password1}`)
        }).then((response) => {
            if (!response.is_success)
                throw new Error("Server didn't respond with success");
            handleClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };

    const handleClose = () => {
        setSavingError("");
        setShowPassword(false);
        props.onClose();
    };

    return (
        <Dialog
            maxWidth={"sm"} fullWidth
            open={!!props.user}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Change the password</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: "1rem"}}>
                    Input the new password twice for user <b>{!!props.user ? props.user.email : ""}</b>
                </DialogContentText>
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel htmlFor="password1">New password</InputLabel>
                    <OutlinedInput
                        id="password1"
                        type={showPassword ? 'text' : 'password'}
                        name="password1"
                        label="New password"
                        fullWidth
                        inputProps={{minLength: 5}}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel htmlFor="password2">Repeat password</InputLabel>
                    <OutlinedInput
                        id="password2"
                        type={showPassword ? 'text' : 'password'}
                        name="password2"
                        label="Repeat password"
                        fullWidth
                        inputProps={{minLength: 5}}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Change</Button>
            </DialogActions>
        </Dialog>
    )

};

export default UserChangePassword;
