import axios from "axios";

export default class ServerService {

    ///////////////////////////AUTH////////////////////////////
    static async getAuth(log_pass, login, controller) {
        const response = await ServerService.sendRequest(controller, '/api/get_auth', {log_pass, login});
        return response.data;
    };

    static async simplePoll(controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/simple_poll', {});
        return response.data;
    };

    ///////////////////////////USERS////////////////////////////
    static async getUsers(controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/get_users', {});
        return response.data;
    };
    static async logout(controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/logout', {});
        return response.data;
    };
    static async userPasswordChange(new_pass_obj, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/user_change_pass', new_pass_obj);
        return response.data;
    };
    static async getUserInfo(user_id, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/get_user_info', {user_id});
        return response.data;
    };
    static async userCreate(new_user_obj, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/create_new_user', {user: new_user_obj});
        return response.data;
    };
    static async userEdit(edit_user_obj, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/edit_user', {user: edit_user_obj});
        return response.data;
    };
    static async userRemove(user_id, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/remove_user', {user_id});
        return response.data;
    };
    ///////////////////////////DEVICES////////////////////////////
    static async getDevices(controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/get_devices', {});
        return response.data;
    };
    static async deviceCreate(new_device_obj, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/create_new_device', {device: new_device_obj});
        return response.data;
    };
    static async deviceEdit(edit_device_obj, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/edit_device', {device: edit_device_obj});
        return response.data;
    };
    static async deviceRemove(device_id, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/remove_device', {device_id});
        return response.data;
    };
    ///////////////////////////COUPONS////////////////////////////
    static async getCoupons(coupon_id, page_index, page_size, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/get_coupons', {coupon_id, page_index, page_size});
        return response.data;
    };
    static async couponCreate(new_coupon_obj, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/create_new_coupon', {coupon: new_coupon_obj});
        return response.data;
    };
    static async couponRemove(coupon_id, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/remove_coupon', {coupon_id});
        return response.data;
    };
    static async couponCancel(coupon_id, controller) {
        const response = await ServerService.sendRequest(controller, '/api_cp/cancel_coupon', {coupon_id});
        return response.data;
    };
    //DISCOUNTS
    static async getDiscounts(controller, coupon_id, page_index, page_size) {
        const response = await ServerService.sendRequest(controller, '/api_cp/get_discounts', {coupon_id, page_index, page_size});
        return response.data;
    };


    static async sendRequest(controller, url, data, response_type = 'json') {
        if (!!!controller) {
            controller = new AbortController();
        }
        try {
            let token = "";
            try {
                token = JSON.parse(localStorage.getItem("user")).token;

            } catch (e) {
                console.log(e);
            }
            if(!token)
                console.log(`token=${localStorage.getItem("user")}`);
            return await axios.post(
                url,
                {...data, token},
                {
                    signal: controller.signal,
                    responseType: response_type
                });
        } catch (e) {
            if (e.response && e.response.status === 401) {
                window.location.href = '/cp/login?auto_logout=1';
            }
            else if (e.response && e.response.status === 403) {
                window.location.href = '/cp/forbidden';
            }
            throw e;
        }
    }


    //localStorage.token
}
