import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import * as c from "../../constants";
import Chip from "@mui/material/Chip";
import moment from "moment-timezone";
import TableBody from "@mui/material/TableBody";



const DiscountView = (props) => {

    const handleClose = () => {
        props.onClose();
    };

    if (!!!props.discount)
        return null;

    return (
        <Dialog open={!!props.discount} onClose={handleClose} PaperProps={{component: 'div'}} maxWidth={"sm"} fullWidth>
            <DialogTitle>Discount <b>{!!props.discount ? props.discount.discount_id : ""}</b> info</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">Status</TableCell>
                            <TableCell>
                                <Chip label={c.get_discount_status_by_id(props.discount.status)} sx={{minWidth: 100}}
                                      color={props.discount.status === c.DISCOUNT_STATUS.APPLIED ? "success" : (props.discount.status === c.DISCOUNT_STATUS.HOLD ? "warning" : "error")}
                                      variant="outlined"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Coupon ID</TableCell>
                            <TableCell><Link to={`../coupons?coupon_id=${props.discount.coupon_id}`}>{props.discount.coupon_id}</Link></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Applied at</TableCell>
                            <TableCell>{moment.utc(props.discount.apply_datetime).tz(props.discount.timezone).format("DD.MM.YYYY HH:mm:ss Z")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Device</TableCell>
                            <TableCell>{props.discount.device_no}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Value</TableCell>
                            <TableCell>{props.discount.amount}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )

};

export default DiscountView;
