import React, {useContext, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";
import moment from "moment-timezone";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterMoment} from '@mui/x-date-pickers-pro/AdapterMoment';
import {DatePicker, TimeField, TimePicker} from '@mui/x-date-pickers'
import Paper from "@mui/material/Paper";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import {Add} from "@mui/icons-material";
import Box from "@mui/material/Box";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from "@mui/material/IconButton";

const maxEmailInputFields = 10;

const CouponCreate = (props) => {
    const dispatch = useContext(DispatchContext);
    let product_types = [...props.productTypeList];
    product_types.push({product_type_id: 0, name: "All", obj_name: "ALL"});
    product_types = product_types.sort((a, b) => Number(a.product_type_id) - Number(b.product_type_id));
    product_types = product_types.map((el) => {
        return {...el, is_checked: true}
    });
    //console.log(product_types);
    const [savingError, setSavingError] = useState("");
    const [productSelectError, setProductSelectError] = useState(false);
    const [productSelect, setProductSelect] = useState(product_types);
    const [validFromDate, setValidFromDate] = useState(moment());
    const [validToDateMin, setValidToDateMin] = useState(moment().add(1, 'day'));
    const [validFromTime, setValidFromTime] = useState(moment().startOf('day').format("HH:mm"));
    const [validToTimeMin, setValidToTimeMin] = useState(moment().startOf('day').add(1, "minute").format("HH:mm"));
    const [deviceId, setDeviceId] = useState(props.deviceList.length > 0 ? props.deviceList[0].device_id : 0);
    const [amountType, setAmountType] = useState(1);
    const [emailFields, setEmailFields] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (productSelectError) {
            setSavingError("Check all required fields");
            return;
        }
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        if (moment(formJson.valid_to, 'DD.MM.YYYY').diff(moment(formJson.valid_from, 'DD.MM.YYYY'), 'day') < 1) {
            setSavingError("Check valid dates");
            return;
        }
        if (moment(formJson.valid_time_to, 'HH:mm').diff(moment(formJson.valid_time_from, 'HH:mm'), 'minute') < 1) {
            setSavingError("Check valid time");
            return;
        }
        setSavingError("");
        const new_coupon_obj = {
            amount_type: formJson.amount_type,
            amount_value: formJson.amount_value,
            device_id: formJson.device_id,
            day_limit: formJson.day_limit,
            total_limit: formJson.total_limit,
            valid_from: formJson.valid_from,
            valid_to: formJson.valid_to,
            valid_time_from: formJson.valid_time_from,
            valid_time_to: formJson.valid_time_to,
            product_types: productSelect.filter(el => el.is_checked),
            emails: emailFields.map((el) => el.value).filter(value => value.trim() !== ""),
            description: formJson.description,
            coupon_name: formJson.coupon_name
            //is_active: !!formJson.is_active
        };

        //console.log(new_coupon_obj);
        //return;
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.couponCreate(new_coupon_obj).then((response) => {
            if (response.err) {
                console.log(response.err);
                setSavingError(response.err);
                return;
            }
            setSavingError("");
            props.onSave();
            props.onClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };
    const handleProductSelectChange = (event) => {
        const edit_checked = !!event.target.checked;
        const edit_name = event.target.name;
        //console.log(edit_name, edit_checked);

        const product_types = [...productSelect];
        //console.log(product_types);
        if (edit_name === "ALL") {
            for (let product_type of product_types) {
                product_type.is_checked = edit_checked;
            }
            setProductSelect(product_types);
            setProductSelectError(!edit_checked);
            return;
        }
        let is_at_least_one_selected = false;
        let all_selected = true;
        for (let product_type of productSelect) {
            if (product_type.obj_name === edit_name)
                product_type.is_checked = edit_checked;
            is_at_least_one_selected |= product_type.is_checked;
            if (product_type.obj_name !== "ALL")
                all_selected &= product_type.is_checked;
        }
        for (let product_type of productSelect) {
            if (product_type.obj_name === "ALL") {
                product_type.is_checked = !!all_selected;
            }
        }
        setProductSelect(product_types);
        setProductSelectError(!is_at_least_one_selected);
    };

    const handleValidFromChange = (value) => {
        //console.log(value);
        setValidToDateMin(value.add(1, 'day'));
    };

    const handleValidTimeFromChange = (value) => {
        //console.log(value);
        setValidToTimeMin(value.add(1, 'minute'));
    };

    const handleClose = () => {
        setSavingError("");
        setAmountType(1);
        setDeviceId(props.deviceList.length > 0 ? props.deviceList[0].device_id : 0);
        setProductSelect(product_types);
        setValidFromDate(moment());
        setValidFromTime(moment().startOf('day'));
        //setValidToDate(moment().add(1, 'day'));
        setValidToDateMin(moment().startOf('day').add(1, 'minute'));
        setEmailFields([]);
        props.onClose();
    };

    const handleAddEmailButtonClick = () => {
        const max_email_id = emailFields.reduce((acc, cur_rec) => {
            return cur_rec.id > acc ? cur_rec.id : acc
        }, 0);
        setEmailFields([...emailFields, {id: max_email_id + 1, value: ""}]);
    };

    const handleDelEmailButtonClick = (removing_id) => {
        setEmailFields(emailFields.filter(el => el.id !== removing_id));
    };

    const handleEmailChanged = (email_id, new_value) => {
        setEmailFields(emailFields.map(el => {
            if(el.id === email_id) {
                el.value = new_value;
            }
            return el;
        }));
        //console.log();
    };

    return (
        <Dialog
            maxWidth={"md"}
            fullWidth
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Create a new coupon</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{m: 1, minWidth: 200}}
                    size="small"
                    autoFocus
                    margin="dense"
                    id="coupon_name"
                    name="coupon_name"
                    label="Name"
                    type="text"
                    defaultValue={""}
                    inputProps={{maxLength:50}}
                    variant="outlined"
                    fullWidth
                />

                <FormControl sx={{m: 1, minWidth: 120}} size="small">
                    <InputLabel id="select_device_lbl">Device</InputLabel>
                    <Select
                        labelId="select_device_lbl"
                        id="device_id"
                        name="device_id"
                        autoFocus
                        margin="dense"
                        required
                        variant="outlined"
                        value={deviceId}
                        onChange={(e) => {
                            setDeviceId(Number(e.target.value))
                        }}
                        label="Device"
                        //onChange={handleDeviceChange}
                    >
                        {props.deviceList.map((device) => (
                            <MenuItem
                                href={null}
                                key={device.device_id}
                                value={device.device_id}
                                //style={getStyles(name, personName, theme)}
                            >
                                {device.device_no}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    sx={{m: 1, minWidth: 200}}
                    size="small"
                    autoFocus
                    required
                    margin="dense"
                    id="total_limit"
                    name="total_limit"
                    label="Total limit (0 - unlimited)"
                    type="number"
                    defaultValue={0}
                    inputProps={{min: 0, max: 9999}}
                    //onChange={(e)=>{setTotalLimit(Number(e.target.value))}}
                    variant="outlined"
                />
                <TextField
                    sx={{m: 1, minWidth: 200}}
                    size="small"
                    autoFocus
                    required
                    margin="dense"
                    id="day_limit"
                    name="day_limit"
                    label="Limit in a day (0 - unlimited)"
                    type="number"
                    defaultValue={0}
                    //onChange={(e)=>{setLimitInDay(Number(e.target.value))}}
                    inputProps={{min: 0, max: 9999}}
                    variant="outlined"
                />
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Paper component={"div"} sx={{display: "flex"}} elevation={0}>
                            <DatePicker
                                name="valid_from"
                                required
                                disablePast={true}
                                sx={{m: 1, maxWidth: 200}}
                                label="Valid from"
                                //maxDate={validToDate.subtract(1, 'day')}
                                format={"DD.MM.YYYY"}
                                //defaultValue={moment()}
                                value={validFromDate}
                                onChange={handleValidFromChange}
                            />
                            <DatePicker
                                name="valid_to"
                                required
                                disablePast={true}
                                //minDate={validFromDate.add(1, 'day')}
                                sx={{m: 1, maxWidth: 200}}
                                label="Valid to"
                                format={"DD.MM.YYYY"}
                                defaultValue={moment().add(1, "month")}
                                minDate={validToDateMin}
                                //value={validToDate}
                                //onChange={handleValidPeriodChange}
                            />
                        </Paper>
                    </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Paper component={"div"} sx={{display: "flex"}} elevation={0}>
                            <TimeField
                                name="valid_time_from"
                                views={['hours', 'minutes']}
                                required
                                //disablePast={true}
                                sx={{m: 1, maxWidth: 200}}
                                label="Work time from"
                                format="HH:mm"
                                defaultValue={moment().startOf("day")}
                                onChange={handleValidTimeFromChange}
                            />
                            <TimeField
                                name="valid_time_to"
                                views={['hours', 'minutes']}
                                required
                                //disablePast={true}
                                sx={{m: 1, maxWidth: 200}}
                                label="Work time to"
                                format="HH:mm"
                                defaultValue={moment().endOf("day")}
                                minTime={moment(validToTimeMin, "HH:mm")}
                            />
                        </Paper>
                    </LocalizationProvider>
                </FormControl>
                <Paper component={"div"} sx={{display: "flex"}} elevation={0}>
                    <TextField
                        autoFocus
                        required
                        sx={{m: 1, minWidth: 120}}
                        size="small"
                        margin="dense"
                        id="amount_value"
                        name="amount_value"
                        label="Discount"
                        type="number"
                        defaultValue={100}
                        inputProps={{min: 1, max: Number(amountType) === 1 ? 100 : 9999}}
                        variant="outlined"
                    />
                    <FormControl>
                        <FormLabel id="select-amount-type-label">Discount type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="select-amount-type-label"
                            id="amount_type"
                            name="amount_type"
                            value={amountType}
                            onChange={(e) => {
                                setAmountType(e.target.value)
                            }}
                            variant="outlined"
                        >
                            <FormControlLabel value="1" control={<Radio/>} label="Percents(%)"/>
                            <FormControlLabel value="2" control={<Radio/>} label="Absolute(money)"/>
                        </RadioGroup>
                    </FormControl>

                </Paper>
                <FormControl
                    required
                    error={productSelectError}
                    component="fieldset"
                    sx={{marginTop: 2, marginBottom: 2}}
                    variant="outlined"
                >
                    <FormLabel component="legend">Pick the products for discount</FormLabel>
                    <FormGroup row>
                        {productSelect.map((product_type) => (
                            <FormControlLabel
                                key={product_type.obj_name}
                                control={
                                    <Checkbox checked={product_type.is_checked}
                                              onChange={handleProductSelectChange} name={product_type.obj_name}
                                              key={product_type.obj_name}
                                    />
                                }
                                label={product_type.name}
                            />
                        ))}
                    </FormGroup>
                    <FormHelperText>Select at least one product type</FormHelperText>
                </FormControl>
                <FormControl fullWidth sx={{marginTop: 1}}>
                    <FormLabel component="legend">Description</FormLabel>
                    <textarea
                        id="description"
                        name="description"
                        rows={4}
                        cols={40}
                        placeholder="Users will see this message"
                    />
                </FormControl>
                <FormControl
                    required
                    component="fieldset"
                    sx={{marginTop: 1}}
                    variant="outlined"
                >
                    <FormLabel component="legend">Add email to send the discount url</FormLabel>
                    {emailFields.map((email_rec) => (
                        <Box component={"div"} sx={{display: "flex", gap: 1}} key={`email_${email_rec.id}`}>
                            <TextField id={`email_${email_rec.id}`} name={`email_${email_rec.id}`} placeholder={`${email_rec.id}`}
                                       size="small"
                                       type="email" component={"div"}
                                       margin="dense" value={email_rec.value}
                                       onChange={(e)=> handleEmailChanged(email_rec.id, e.target.value)}
                            />
                            <IconButton aria-label="delete" variant="contained" color="primary" onClick={() => handleDelEmailButtonClick(email_rec.id)}>
                                <RemoveCircleOutlineIcon/>
                            </IconButton>
                        </Box>
                    ))}
                    {emailFields.length < maxEmailInputFields && <Box component={"div"}>
                        <Button variant="contained" color="primary" onClick={handleAddEmailButtonClick}>
                            <Add/>
                        </Button>
                    </Box>}
                </FormControl>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Create</Button>
            </DialogActions>
        </Dialog>
    )

};

export default CouponCreate;
