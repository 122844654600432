import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuProps from "../Accessory/MenuProps";


const UserEdit = (props) => {
    const dispatch = useContext(DispatchContext);
    const [savingError, setSavingError] = useState("");
    const [selectedRoleId, setSelectedRoleId] = useState(0);
    const [userName, setUserName] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [userDeviceList, setUserDeviceList] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        setSavingError("");

        const edit_user_obj = {
            user_id: props.user.user_id,
            user_role_id: parseInt(formJson.user_role_id),
            device_list: formJson.device_list ? formJson.device_list.split(",") : [],
            name: formJson.name,
            is_active: !!formJson.is_active
        };
        //console.log(new_user_obj);
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.userEdit(edit_user_obj).then((response) => {
            if (response.err) {
                setSavingError(response.err);
                return;
            }
            setSavingError("");
            props.onSave();
            props.onClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };

    useEffect(() => {
        setSelectedRoleId(props.user ? props.user.user_role_id : c.USER_ROLES.ADMIN);
        setUserName(props.user ? props.user.name : "");
        setIsActive(props.user ? props.user.is_active : false);
        setUserDeviceList(props.user ? props.user.dev_list.map(dev => dev.device_id) : []);
    }, [props.user]);

    const handleClose = () => {
        setSavingError("");
        props.onClose();
    };

    const handleDeviceChange = (event) => {
        const {
            target: { value },
        } = event;
        setUserDeviceList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    if (!!!props.user)
        return null;

    return (
        <Dialog
            maxWidth={"sm"} fullWidth
            open={!!props.user}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle component={"div"}>Edit user <b>{!!props.user ? props.user.email : ""}</b></DialogTitle>
            <DialogContent>
                <FormControl fullWidth variant="outlined" margin="dense" component={"div"}>
                    <InputLabel id="user_role_id">Role</InputLabel>
                    <Select
                        autoFocus
                        required
                        labelId="user_role_id"
                        id="user_role_id"
                        name="user_role_id"
                        value={selectedRoleId}
                        label="Role"
                        variant="outlined"
                        onChange={(event) => setSelectedRoleId(event.target.value)}
                    >
                        {
                            props.roleList.map((cur_role) =>
                                <MenuItem key={cur_role.user_role_id + cur_role.role_name} href={null}
                                          value={cur_role.user_role_id}>{cur_role.role_name}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    value={userName}
                    inputProps={{minLength: 3, maxLength: 30}}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                        setUserName(e.target.value);
                    }}
                />
                {selectedRoleId !== c.USER_ROLES.ADMIN &&
                <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel id="select_device_label">Devices</InputLabel>
                    <Select
                        labelId="select_device_label"
                        id="user_device_list"
                        name="device_list"
                        autoFocus
                        multiple
                        variant="outlined"
                        value={userDeviceList}
                        onChange={handleDeviceChange}
                        input={<OutlinedInput id="select_device" label="Device" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {
                                    props.deviceList.filter(dev => selected.includes(dev.device_id)).map((dev) => (
                                        <Chip key={dev.device_id} label={dev.device_no} />
                                    ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {props.deviceList.map((device) => (
                            <MenuItem
                                key={device.device_id}
                                value={device.device_id}
                                //style={getStyles(name, personName, theme)}
                            >
                                {device.device_no}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
                <FormGroup variant="outlined" margin="dense">
                    <FormControlLabel id="is_active" name="is_active" label="Active"
                                      control={<Checkbox checked={isActive} onChange={(e) => {
                                          setIsActive(e.target.checked);
                                      }}/>}/>
                </FormGroup>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button href={null} onClick={handleClose}>Cancel</Button>
                <Button href={null} type="submit">Save</Button>
            </DialogActions>
        </Dialog>
    )

};

export default UserEdit;
