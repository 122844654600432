import React, {useContext, useEffect, useMemo, useState} from 'react';
import DispatchContext from "../../DispatchContext";
import StateContext from "../../StateContext";
import {useNavigate} from "react-router";
import * as c from "../../constants";
import ServerService from "../../API/ServerService";
import LoadingIconSpan from "../Accessory/LoadingIconSpan";
import {
    Box,
    Button,
    Typography,
    IconButton,
    Paper,
    Tooltip
} from '@mui/material';
import {Delete, Edit, NotInterested, Done, Search} from '@mui/icons-material';
import {MaterialReactTable} from 'material-react-table';
import axios from "axios";
import DeviceCreate from "./DeviceCreate";
import DeviceRemove from "./DeviceRemove";
import DeviceEdit from "./DeviceEdit";
import DeviceView from "./DeviceView";
import Chip from "@mui/material/Chip";

const Devices = () => {
    const [deviceList, setDeviceList] = useState([]);
    //const [userRolesList, setUserRolesList] = useState([]);
    const [needTableUpdate, setNeedTableUpdate] = useState(true);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [removeDeviceModalOpen, setRemoveDeviceModalOpen] = useState(null);
    const [editDeviceModalOpen, setEditDeviceModalOpen] = useState(null);
    const [viewDeviceModalOpen, setViewDeviceModalOpen] = useState(null);
    const state = useContext(StateContext);
    const navigate = useNavigate();

    const columns = [
        {
            accessorKey: 'device_id',
            header: 'ID',
            enableColumnOrdering: false,
            enableEditing: false, //disable editing on this column
            enableSorting: false,
            size: 30,
        },
        {
            accessorKey: 'device_no',
            header: 'Number',
            size: 30
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                <Chip label={c.get_dev_status_by_id(renderedCellValue)} sx={{minWidth: 100}}
                      color={renderedCellValue === c.DEV_STATUS.ONLINE ? "success" :
                          (renderedCellValue === c.DEV_STATUS.NEED_UPDATE || renderedCellValue === c.DEV_STATUS.INVALID_KEY? "warning" :
                              (renderedCellValue === c.DEV_STATUS.OFFLINE ? "error" : "warning"))}
                      variant="outlined"/>
            ),
        },
        {
            accessorKey: 'sw_version',
            header: 'S/W',
            size: 30
        },
        {
            accessorKey: 'ip_ext',
            header: 'IP ext',
            size: 40
        },
        {
            accessorKey: 'description',
            header: 'Description',
            size: 140,
            Cell: ({renderedCellValue, row}) => (
                renderedCellValue ? (renderedCellValue.length > 30 ?
                    <Tooltip arrow placement="left" title={renderedCellValue}>
                        <div>{`${renderedCellValue.slice(0, 27)}...`}</div>
                    </Tooltip> : renderedCellValue) : ""
            ),
        },
        {
            accessorKey: 'is_active',
            header: 'Active',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                renderedCellValue ? <IconButton color="info"><Done/></IconButton> :
                    <IconButton color="error"><NotInterested/></IconButton>
            ),
        }
    ];

    useEffect(() => {
        if (!needTableUpdate)
            return;
        let axios_controller = new AbortController();
        ServerService.getDevices(axios_controller).then((response) => {
            if (response.devices)
                setDeviceList(response.devices);
            //if (response.roles)
            //    setUserRolesList(response.roles);
            setNeedTableUpdate(false);
        }).catch((e) => {
            if (axios.isCancel(e)) {
                return;
            }
            setNeedTableUpdate(false);
        }).finally(() => {

        });
        return (() => {
            axios_controller.abort();
        });
    }, [needTableUpdate]);

    if (needTableUpdate)
        return (
            <LoadingIconSpan is_visible={true}/>
        );

    return (
        <React.Fragment>
            <DeviceView device={viewDeviceModalOpen} onClose={() => setViewDeviceModalOpen(null)}/>
            {state.user.user_role_id === c.USER_ROLES.ADMIN && <React.Fragment>
                <DeviceEdit device={editDeviceModalOpen} onClose={() => setEditDeviceModalOpen(null)}
                            onSave={() => {
                                setNeedTableUpdate(true)
                            }}/>
                <DeviceRemove device={removeDeviceModalOpen} onClose={() => setRemoveDeviceModalOpen(null)}
                              onSave={() => {
                                  setNeedTableUpdate(true)
                              }}/>
                <DeviceCreate onClose={() => setCreateModalOpen(false)} open={createModalOpen}
                              onSave={() => {
                                  setNeedTableUpdate(true)
                              }}/></React.Fragment>}

            <Paper style={{maxWidth: "70rem", margin: "auto"}}>
                <Typography component="h1" variant="h4">Devices</Typography>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 100,
                        },
                    }}
                    initialState={{density: 'compact'}}
                    enablePagination={false}
                    columns={columns}
                    data={deviceList}
                    enableEditing
                    enableTopToolbar={state.user.user_role_id === c.USER_ROLES.ADMIN}
                    enableBottomToolbar={false}
                    enableGlobalFilter={false} //disable search feature
                    enableFilterMatchHighlighting={false}
                    enableColumnActions={false}
                    enableSorting={false} //disable sorting for all columns
                    enableToolbarInternalActions={false}
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip arrow placement="left" title="View">
                                <IconButton onClick={() => {
                                    setViewDeviceModalOpen(row.original);
                                }}>
                                    <Search/>
                                </IconButton>
                            </Tooltip>
                            {state.user.user_role_id === c.USER_ROLES.ADMIN &&
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => {
                                    setEditDeviceModalOpen(row.original);
                                }}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>}
                            {state.user.user_role_id === c.USER_ROLES.ADMIN &&
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton onClick={() => {
                                    setRemoveDeviceModalOpen(row.original);
                                }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>}
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
                            Create
                        </Button>
                    )}
                />
            </Paper>
        </React.Fragment>
    );
};

export default Devices;
