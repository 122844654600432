import React, {useContext, useEffect, useState} from 'react';
import classes from './LoginPage.module.css'
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import {sha256} from "js-sha256";
import * as c from "../../constants";
import LoadingButton from "@mui/lab/LoadingButton";
import ServerService from "../../API/ServerService";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import StateContext from "../../StateContext";
import DispatchContext from "../../DispatchContext";
import {useParams} from "react-router";
//import {socket} from "../../SocketContext";

const LoginPage = () => {
    const [loginError, setLoginError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [logPass, setLogPass] = useState(null);
    const navigate = useNavigate();
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    let {auto_logout} = useParams();

    useEffect(() => {
        if(auto_logout) {
            dispatch({type: c.SET_USER, payload: null});
            return;
        }
        if (!state.user || !state.user.token)
            return;
        const axios_controller = new AbortController();
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.simplePoll(axios_controller).then((response) => {
            if (response.is_token_valid) {
                //socket.emit("SOCKET_LOGIN", {token: localStorage.getItem("token")});
                navigate("/cp");
            } else {
                dispatch({type: c.SET_USER, payload: null});
            }
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            dispatch({type: c.SET_USER, payload: null});
            console.error(`API ERR ${err}`);
        }).finally(() => {

            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });

        return (() => {
            axios_controller.abort();
        });
    }, []);

    useEffect(() => {
        if (!logPass || !logPass.log_pass || !logPass.login)
            return;
        setIsLoading(true);
        setLoginError("");
        const axios_controller = new AbortController();
        ServerService.getAuth(logPass.log_pass, logPass.login, axios_controller).then((response) => {
            setIsLoading(false);
            if (response.error) {
                setLoginError(response.error);
                setLogPass(null);
                return;
            }
            if (!response.user) {
                setLoginError("Неверный логин/пароль");
                setLogPass(null);
                return;
            }
            dispatch({type: c.SET_USER, payload: response.user});
            navigate("/cp");
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            console.error(`API ERR ${err}`);
            setIsLoading(false);
            setLogPass(null);
            setLoginError("Server is not available");
        });

        return (() => {
            axios_controller.abort();
        });

    }, [logPass]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const login = data.get('login');
        const password = data.get('password');
        if (!login || !password) {
            setLoginError("Login and password can't be empty");
            return;
        }
        setLogPass({log_pass: sha256(`${login}+${password}`), login});
    };

    if (state.is_page_loading)
        return null;

    return (
        <div className={classes.login_form}>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Authorization
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="login"
                    label="Login"
                    name="login"
                    autoComplete="email"
                    type="text"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <div className={classes.login_error_wr}><div className={classes.login_error}>{loginError}</div></div>
                <LoadingButton fullWidth type="submit" loading={isLoading} variant="contained" sx={{mt: 3, mb: 2}}>
                    Sign in
                </LoadingButton>
            </Box>
        </div>
    );
};

export default LoginPage;
