import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";

const DeviceRemove = (props) => {
    const dispatch = useContext(DispatchContext);
    const [savingError, setSavingError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        setSavingError("");
        dispatch({type: c.SET_LOADING_PAGE, payload: true});

        ServerService.deviceRemove(parseInt(props.device.device_id)).then((response) => {
            if (!response.is_success)
                throw new Error("Server didn't respond with success");
            props.onSave();
            props.onClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };

    const handleClose = () => {
        setSavingError("");
        props.onClose();
    };

    return (
        <Dialog
            maxWidth={"sm"} fullWidth
            open={!!props.device}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Remove the device</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: "1rem"}}>
                    Are you sure you want to remove device <b>{!!props.device ? props.device.device_no : ""}</b>?
                </DialogContentText>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" color="error">Remove</Button>
            </DialogActions>
        </Dialog>
    )

};

export default DeviceRemove;
