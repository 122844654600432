import React, {useContext} from "react";
import classes from './LoadingIconSpan.module.css'
import StateContext from "../../StateContext";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingIconSpan = (props) => {
    if (!props.is_visible)
        return null;

    return (
        <div className={classes.loading_icon__wrapper}>
            <CircularProgress color="inherit" className={classes.loading_icon}/>
        </div>);
};

export default LoadingIconSpan;

