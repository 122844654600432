import React, {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {Box, Button, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Delete, Search} from "@mui/icons-material";
import * as c from "../../constants";
import LoadingIconSpan from "../Accessory/LoadingIconSpan";
import Chip from "@mui/material/Chip";
import moment from "moment-timezone";
import ServerService from "../../API/ServerService";
import axios from "axios";
import DiscountView from "./DiscountView";

const Discounts = () => {
    const [discountList, setDiscountList] = useState([]);
    const [viewDiscountModalOpen, setViewDiscountModalOpen] = useState(null);
    const [needTableUpdate, setNeedTableUpdate] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25, //customize the default page size
    });
    const url_params = new URLSearchParams(window.location.search);
    const coupon_id = Number(url_params.get('coupon_id')) ? Number(url_params.get('coupon_id')) : 0;
    let [searchParams, setSearchParams] = useSearchParams();

    const columns = [
        {
            accessorKey: 'apply_datetime',
            header: 'Applied at',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                <Box component={"div"}>{moment.utc(renderedCellValue).tz(row.original.timezone).format("DD.MM.YYYY HH:mm:ss Z")}</Box>
            ),
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                <Chip label={c.get_discount_status_by_id(renderedCellValue)} sx={{minWidth: 100}}
                      color={renderedCellValue === c.DISCOUNT_STATUS.APPLIED ? "success" : (renderedCellValue === c.DISCOUNT_STATUS.HOLD ? "warning" : "error")}
                      variant="outlined"/>
            ),
        },
        {
            accessorKey: 'device_no',
            header: 'Device',
            size: 30
        },
        {
            accessorKey: 'coupon_id',
            header: 'Coupon ID',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                <Link to={`../coupons?coupon_id=${row.original.coupon_id}`}>{renderedCellValue}</Link>
            ),
        },
        {
            accessorKey: 'amount',
            header: 'Value',
            size: 40,
            Cell: ({renderedCellValue, row}) => (
                Number.parseFloat(renderedCellValue).toFixed(2)
            ),
        }
    ];

    useEffect(() => {
        if (!needTableUpdate)
            return;
        let axios_controller = new AbortController();
        ServerService.getDiscounts(axios_controller, coupon_id, pagination.pageIndex, pagination.pageSize).then((response) => {
            if (response.discounts)
                setDiscountList(response.discounts);
            //console.log(response.discounts);
            //if (response.devices)
            //    setDeviceList(response.devices);
            // if (response.product_types)
            //     setProductTypeList(response.product_types);
            if (response.discounts_count)
                 setRowCount(response.discounts_count);
            setNeedTableUpdate(false);
        }).catch((e) => {
            if (axios.isCancel(e)) {
                return;
            }
            setNeedTableUpdate(false);
        }).finally(() => {

        });
        return (() => {
            axios_controller.abort();
        });
    }, [needTableUpdate]);

    useEffect(() => {
        setNeedTableUpdate(true);
    }, [pagination.pageIndex, pagination.pageSize, searchParams]);

    if (needTableUpdate)
        return (
            <LoadingIconSpan is_visible={true}/>
        );

    return (
        <React.Fragment>
            <DiscountView discount={viewDiscountModalOpen} onClose={() => setViewDiscountModalOpen(null)}/>

            <Paper style={{maxWidth: "70rem", margin: "auto"}}>
                <Typography component="h1" variant="h4">Discounts</Typography>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 10,
                        },
                    }}
                    initialState={{density: 'compact'}}
                    enablePagination={true}
                    manualPagination={true}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    state={{pagination}} //pass the pagination state to the table
                    rowCount={rowCount}
                    columns={columns}
                    data={discountList}
                    enableEditing
                    enableTopToolbar={false}
                    enableBottomToolbar={true}
                    enableGlobalFilter={false} //disable search feature
                    enableFilterMatchHighlighting={false}
                    enableColumnActions={false}
                    enableSorting={false} //disable sorting for all columns
                    enableToolbarInternalActions={false}
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip arrow placement="left" title="View">
                                <IconButton onClick={() => {
                                    setViewDiscountModalOpen(row.original);
                                }}>
                                    <Search/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Paper>
        </React.Fragment>
    );
};

export default Discounts;
