import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DispatchContext from "../../DispatchContext";
import * as c from "../../constants";
import Alert from "@mui/material/Alert";
import ServerService from "../../API/ServerService";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment-timezone";


const DeviceCreate = (props) => {
    const dispatch = useContext(DispatchContext);
    const [savingError, setSavingError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        setSavingError("");
        const new_device_obj = {
            ...formJson,
            is_active: !!formJson.is_active
        };
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.deviceCreate(new_device_obj).then((response) => {
            if (response.err) {
                setSavingError(response.err);
                return;
            }
            setSavingError("");
            props.onSave();
            props.onClose();
        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            setSavingError("Error occurred. Try later");
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
        });
    };

    const handleClose = () => {
        setSavingError("");
        props.onClose();
    };

    return (
        <Dialog
            maxWidth={"sm"} fullWidth
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Create a new device</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="device_no"
                    name="device_no"
                    label="Number"
                    type="number"
                    inputProps={{min: 1, max: 9999}}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="ip_int"
                    name="ip_int"
                    label="IP Int"
                    type="text"
                    inputProps={{minLength: 7, maxLength: 21}}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="ip_ext"
                    name="ip_ext"
                    label="IP Ext"
                    type="text"
                    inputProps={{minLength: 7, maxLength: 21}}
                    fullWidth
                    variant="outlined"
                />
                {/*<TextField*/}
                {/*    autoFocus*/}
                {/*    required*/}
                {/*    margin="dense"*/}
                {/*    id="admin_api_key"*/}
                {/*    name="admin_api_key"*/}
                {/*    label="Admin API Key"*/}
                {/*    type="text"*/}
                {/*    inputProps={{minLength: 6, maxLength: 30}}*/}
                {/*    fullWidth*/}
                {/*    variant="outlined"*/}
                {/*/>*/}
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="dev_api_user"
                    name="dev_api_user"
                    label="API User"
                    type="text"
                    inputProps={{minLength: 4, maxLength: 30}}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="dev_api_key"
                    name="dev_api_key"
                    label="API Key"
                    type="text"
                    inputProps={{minLength: 4, maxLength: 30}}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    inputProps={{maxLength: 100}}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    multiline
                    rows={3}
                    margin="dense"
                    id="comment"
                    name="comment"
                    label="Comment"
                    type="text"
                    inputProps={{maxLength: 250}}
                    fullWidth
                    variant="outlined"
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="timezone-select-label">Timezone</InputLabel>
                    <Select
                        labelId="timezone-select-label"
                        id="timezone-select"
                        name="timezone"
                        //value={age}
                        label="Timezone"
                        defaultValue={Object.keys(c.ALL_TIMEZONES).includes(moment.tz.guess()) ? moment.tz.guess() : c.ALL_TIMEZONES.GMT}
                        //onChange={handleChange}
                    >
                        {Object.keys(c.ALL_TIMEZONES).map((key)=>(

                            <MenuItem value={key} key={key}>{`${moment().tz(key).format('Z')} ${c.ALL_TIMEZONES[key]}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormGroup variant="outlined" margin="dense">
                    <FormControlLabel id="is_active" name="is_active" control={<Checkbox defaultChecked/>}
                                      label="Active"/>
                </FormGroup>
                {!!savingError && <Alert severity="error" variant="outlined">{savingError}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Create</Button>
            </DialogActions>
        </Dialog>
    )

};

export default DeviceCreate;
