import * as c from './constants'

export default function (state, {type, payload}) {
    //console.log(type, payload);
    //console.log(state);
    switch (type) {
        case c.SET_LOADING_PAGE :
            return {...state, is_page_loading: payload};

        case c.SET_USER: {
            if(payload && !payload.token)
                payload = {...payload, token: state.user.token};
            localStorage.setItem("user", JSON.stringify(payload));
            return {...state, user: payload};
        }

        default:
            console.log(`Reducer error: unknown action type=${type}; payload=${payload}`);
            return state;
    }

}
