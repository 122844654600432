export const SET_LOADING_PAGE = "SET_LOADING_PAGE";
export const SET_USER = "SET_USER";

export const USER_ROLES = {
    ADMIN: 1,
    OWNER: 2
};

export const COUPON_STATUS = {
    ACTIVE: 1,
    USED: 2,
    CANCELLED: 3,
    EXPIRED: 4
};

export const DISCOUNT_STATUS = {
    HOLD: 1,
    CANCELLED: 2,
    APPLIED: 3
};

export const DISCOUNT_TYPE = {
    "PERCENT": 1,
    "AMOUNT": 2
};
export const DEV_STATUS = {
    "UNKNOWN": 1,
    "OFFLINE": 2,
    "NEED_UPDATE": 3,
    "ONLINE": 4,
    "INVALID_KEY": 5
};

// export const COUPON_LIMIT_TYPE = {
//     UNLIMITED: 1,
//     TIMES: 2,
//     TIMES_PERIOD: 3
// };
//
// export const PRODUCT_TYPES = {
//     ICECREAM: 1,
//     COFFEE: 2,
//     TEA: 3,
//     SNACKS: 4
// };

export const get_coupon_status_by_id = (status_id) => {
    for(let key in COUPON_STATUS)
        if(COUPON_STATUS[key] === status_id)
            return key;
        return "NONE";
};

export const get_discount_status_by_id = (status_id) => {
    for(let key in DISCOUNT_STATUS)
        if(DISCOUNT_STATUS[key] === status_id)
            return key;
    return "NONE";
};

export const get_dev_status_by_id= (status_id) => {
    for(let key in DEV_STATUS)
        if(DEV_STATUS[key] === status_id)
            return key;
    return "UNKNOWN";
};

export const TIMEZONES =
[{val: -720, name: "(GMT -12:00) Eniwetok, Kwajalein"},
{val: -660, name: "(GMT -11:00) Midway Island, Samoa"},
{val: -600, name: "(GMT -10:00) Hawaii"},
{val: -590, name: "(GMT -9:30) Taiohae"},
{val: -540, name: "(GMT -9:00) Alaska"},
{val: -480, name: "(GMT -8:00) Pacific Time (US & Canada)"},
{val: -420, name: "(GMT -7:00) Mountain Time (US & Canada)"},
{val: -360, name: "(GMT -6:00) Central Time (US & Canada), Mexico City"},
{val: -300, name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"},
{val: -290, name: "(GMT -4:30) Caracas"},
{val: -240, name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"},
{val: -230, name: "(GMT -3:30) Newfoundland"},
{val: -180, name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown"},
{val: -120, name: "(GMT -2:00) Mid-Atlantic"},
{val: -60, name: "(GMT -1:00) Azores, Cape Verde Islands"},
{val: 0, name: "(GMT) Western Europe Time, London, Lisbon, Casablanca"},
{val: 60, name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"},
{val: 120, name: "(GMT +2:00) Kaliningrad, South Africa"},
{val: 180, name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"},
{val: 230, name: "(GMT +3:30) Tehran"},
{val: 240, name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"},
{val: 290, name: "(GMT +4:30) Kabul"},
{val: 300, name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"},
{val: 350, name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"},
{val: 360, name: "(GMT +6:00) Almaty, Dhaka, Colombo"},
{val: 410, name: "(GMT +6:30) Yangon, Mandalay"},
{val: 420, name: "(GMT +7:00) Bangkok, Hanoi, Jakarta"},
{val: 480, name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"},
{val: 540, name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"},
{val: 570, name: "(GMT +9:30) Adelaide, Darwin"},
{val: 600, name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok"},
{val: 650, name: "(GMT +10:30) Lord Howe Island"},
{val: 660, name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"},
{val: 690, name: "(GMT +11:30) Norfolk Island"},
{val: 720, name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"}];

export const get_timezone_name = (value) => {
    for(let i = 0; i < TIMEZONES.length; ++i){
        if(TIMEZONES[i].val === value )
            return TIMEZONES[i].name
    }
    return "(GMT) Western Europe Time, London, Lisbon, Casablanca";
};

export const ALL_TIMEZONES = {
    "Pacific/Midway": "Midway Island, Samoa",
    "Pacific/Honolulu": "Hawaii",
    "America/Juneau": "Alaska",
    "America/Phoenix": "Arizona",
    "America/Dawson": "Dawson, Yukon",
    "America/Tijuana": "Tijuana",
    "America/Los_Angeles": "Pacific Time",
    "America/Boise": "Mountain Time",
    "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
    "America/Regina": "Saskatchewan",
    "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
    "America/Belize": "Central America",
    "America/Chicago": "Central Time",
    "America/Bogota": "Bogota, Lima, Quito",
    "America/Detroit": "Eastern Time",
    "America/Caracas": "Caracas, La Paz",
    "America/Santiago": "Santiago",
    "America/Sao_Paulo": "Brasilia",
    "America/Montevideo": "Montevideo",
    "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
    "America/St_Johns": "Newfoundland and Labrador",
    "America/Godthab": "Greenland",
    "Atlantic/Cape_Verde": "Cape Verde Islands",
    GMT: "UTC",
    "Atlantic/Azores": "Azores",
    "Europe/London": "Edinburgh, London",
    "Europe/Dublin": "Dublin",
    "Europe/Lisbon": "Lisbon",
    "Africa/Casablanca": "Casablanca, Monrovia",
    "Atlantic/Canary": "Canary Islands",
    "Africa/Algiers": "West Central Africa",
    "Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
    "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
    "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "Africa/Harare": "Harare, Pretoria",
    "Europe/Bucharest": "Bucharest",
    "Africa/Cairo": "Cairo",
    "Europe/Helsinki": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "Europe/Athens": "Athens",
    "Asia/Jerusalem": "Jerusalem",
    "Europe/Moscow": "Istanbul, Minsk, Moscow, St. Petersburg, Volgograd",
    "Asia/Kuwait": "Kuwait, Riyadh",
    "Africa/Nairobi": "Nairobi",
    "Asia/Baghdad": "Baghdad",
    "Asia/Tehran": "Tehran",
    "Asia/Dubai": "Abu Dhabi, Muscat",
    "Asia/Baku": "Baku, Tbilisi, Yerevan",
    "Asia/Kabul": "Kabul",
    "Asia/Yekaterinburg": "Ekaterinburg",
    "Asia/Almaty": "Almaty, Novosibirsk",
    "Asia/Karachi": "Islamabad, Karachi, Tashkent",
    "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
    "Asia/Colombo": "Sri Jayawardenepura",
    "Asia/Kathmandu": "Kathmandu",
    "Asia/Dhaka": "Astana, Dhaka",
    "Asia/Rangoon": "Yangon Rangoon",
    "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
    "Asia/Krasnoyarsk": "Krasnoyarsk",
    "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
    "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
    "Asia/Taipei": "Taipei",
    "Australia/Perth": "Perth",
    "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
    "Asia/Seoul": "Seoul",
    "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
    "Asia/Yakutsk": "Yakutsk",
    "Australia/Darwin": "Darwin",
    "Australia/Adelaide": "Adelaide",
    "Australia/Sydney": "Canberra, Melbourne, Sydney",
    "Australia/Brisbane": "Brisbane",
    "Australia/Hobart": "Hobart",
    "Asia/Vladivostok": "Vladivostok",
    "Pacific/Guam": "Guam, Port Moresby",
    "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
    "Asia/Kamchatka": "Kamchatka, Marshall Islands",
    "Pacific/Fiji": "Fiji Islands",
    "Pacific/Auckland": "Auckland, Wellington",
    "Pacific/Tongatapu": "Nuku'alofa"
};
