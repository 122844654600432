import React from 'react';
import * as c from "../../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Done, NotInterested} from "@mui/icons-material";
import TableBody from "@mui/material/TableBody";
import moment from "moment-timezone";
import Chip from "@mui/material/Chip";


const DeviceView = (props) => {

    const handleClose = () => {
        props.onClose();
    };

    if (!!!props.device)
        return null;

    return (
        <Dialog open={!!props.device} onClose={handleClose} PaperProps={{component: 'div'}} maxWidth={"sm"} fullWidth>
            <DialogTitle>Device <b>{!!props.device ? props.device.device_no : ""}</b> info</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">Status</TableCell>
                            <TableCell>
                                <Chip label={c.get_dev_status_by_id(props.device.status)} sx={{minWidth: 100}}
                                      color={props.device.status === c.DEV_STATUS.ONLINE ? "success" :
                                          (props.device.status === c.DEV_STATUS.NEED_UPDATE || props.device.status === c.DEV_STATUS.INVALID_KEY ? "warning" :
                                              (props.device.status === c.DEV_STATUS.OFFLINE ? "error" : "warning"))}
                                      variant="outlined"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">S/W</TableCell>
                            <TableCell>{props.device.sw_version}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Active</TableCell>
                            <TableCell>
                                {props.device.is_active ? <Done color={"info"}/> : <NotInterested color={"error"}/>}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Device ID</TableCell>
                            <TableCell>{props.device.device_id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Number</TableCell>
                            <TableCell>{props.device.device_no}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell variant="head">IP Int</TableCell>
                            <TableCell>{props.device.ip_int}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">IP Ext</TableCell>
                            <TableCell>{props.device.ip_ext}</TableCell>
                        </TableRow>
                        {/*<TableRow>*/}
                        {/*    <TableCell variant="head">Admin API Key</TableCell>*/}
                        {/*    <TableCell>{props.device.admin_api_key}</TableCell>*/}
                        {/*</TableRow>*/}
                        <TableRow>
                            <TableCell variant="head">API User</TableCell>
                            <TableCell>{props.device.dev_api_user}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">API Key</TableCell>
                            <TableCell>{props.device.dev_api_key}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Description</TableCell>
                            <TableCell>{props.device.description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Comment</TableCell>
                            <TableCell>{props.device.comment}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Timezone</TableCell>
                            <TableCell>{
                                Object.keys(c.ALL_TIMEZONES).includes(props.device.timezone) ?
                                    `${moment().tz(props.device.timezone).format('Z')} ${c.ALL_TIMEZONES[props.device.timezone]}` :
                                    `${moment().tz("GMT").format('Z')} ${c.ALL_TIMEZONES.GMT}`
                            }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )

};

export default DeviceView;
